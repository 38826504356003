declare global {
    interface Window {
        ReactNativeWebView?: {
            postMessage: (msg: string) => void;
        };
    }
}
interface WebviewMessageIf {
    payload?: any;
    type: string;
};
export class WebviewUtil {
    static isWebview() {
        return !!window.ReactNativeWebView;
    }
    static emit(data: string | WebviewMessageIf) {
        if (!WebviewUtil.isWebview()) return;
        try {
            let msg = typeof data === 'string' ? data : JSON.stringify(data);
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage(msg);    
        } catch (e) {
            console.error(e);
        }
    }
};