import { format } from "date-fns";
import React from "react";
import { CardProps } from "../../interfaces/card.interface";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { fixDecimal } from "../../utils/fixDecimal";
import { Tooltip } from "../tooltip/tooltip";
import "./card.scss";
import logo from "./earn-logo-green.svg";

export const Card: React.FC<CardProps> = ({
  startDate,
  days,
  endDate,
  currentData,
  showDailyData,
  reward,
  currency,
  estimate_reward,
  estimate_currency,
  ave_apy,
  children,
}) => {
  return (
    <div className="card">
      <div className="card__title">
        <img
          src={logo}
          alt="earn logo"
          className="card__title__icon"
          width="10"
          height="10"
        />
        Earn summary
      </div>
      <div className="card__content">
        <div className="card__content__title">
          <p className="text text--white--sm">
            {showDailyData
              ? currentData?.date
              : `${format(startDate, "MM/dd")} - ${format(endDate, "MM/dd")}`}
          </p>
        </div>
        <div className="card__content__row">
          <div className="card__content__item">
            <div className="text text--gray tooltip__wrapper">
              {showDailyData ? "APR" : `${days}-day avg. APR`}
              <Tooltip  day={days}/>
            </div>
            <p className="text text--green">
              {fixDecimal(showDailyData ? currentData?.apy || 0 : ave_apy, 2)}%
            </p>
          </div>
          <div className="card__content__item">
            <p className="text text--gray">
              {showDailyData ? "Reward" : `${days}-day reward`}
            </p>
            <p className="text text--green">
              {`+${showDailyData ? currencyFormatter(currentData?.reward, currency) : currencyFormatter(reward, currency)} ${currency}`}
            </p>
            <p className="text text--gray">
              ≈{" "}
              {showDailyData
                ? currencyFormatter(currentData?.estimate_reward, currency)
                : currencyFormatter(estimate_reward, currency)}{" "}
              {estimate_currency}
            </p>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
