export enum ApiResponseStatus {
  Success = "0",
}

export interface EarnRewards {
  reward: string;
  currency: string;
  estimate_reward: string;
  estimate_currency: string;
  date: string;
  apy: number;
}
export interface ChartRewardData  {
  reward: string;
  currency: string;
  estimate_reward: string;
  estimate_currency: string;
  date: Date;
  apy: number;
  avgApy: number;
}
export interface ChartStateData  {
  reward: string;
  currency: string;
  estimate_reward: string;
  estimate_currency: string;
  date: string;
  apy: number;
  avgApy: number;
}
export interface EarnDetailStatistics {
  days: number;
  currency: string;
  reward: string;
  estimate_currency: string;
  estimate_reward: string;
  ave_apy: number;
  reward_records: EarnRewards[];
}

export interface EarnDetailStatisticsResponse {
  code: ApiResponseStatus;
  desc: string;
  data: EarnDetailStatistics;
  request_id: string;
  timestamp: string;
}

export interface StakingDataState {
  reward_records: ChartRewardData[] | undefined;
  reward: string;
  estimate_reward: string;
  estimate_currency: string;
  startDate: Date;
  endDate: Date;
  days: number;
  currency: string;
  ave_apy: number;
  showDailyData?: boolean;
}

export interface UseChartDataResult {
  isLoading: boolean;
  data: StakingDataState | undefined;
}
export interface GetAumProformanceRequestBody {
  token: string;
  productId:string;
  days: number;
};