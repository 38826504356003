import { ChartView } from "../../components/chartView/chartView";
import { Card } from "../../components/card/card";
import { UseChartData } from "../../hooks/useChartData";
import { useState } from "react";
import {
  ChartRewardData,
  ChartStateData,
} from "../../interfaces/userChartData.interface";
import format from "date-fns/format";
import "./chart.scss";

function Chart() {
  const { data, isLoading } = UseChartData();
  const [currentDate, setCurrentDate] = useState<ChartStateData>();
  const [showDailyData, setShowDailyData] = useState<boolean>(false);

  if (isLoading || !data) {
    return <div className="chartWrapper"></div>;
  }

  const onDateChange = (shouldShowDailyData: boolean, currentDate: any) => {
    if (currentDate) {
      let date: ChartRewardData | undefined = data.reward_records?.find(
        (record: ChartRewardData) => {
          return (
            format(record.date, "yy-MM-dd") === format(currentDate, "yy-MM-dd")
          );
        }
      );

      if (!date) {
        return;
      }

      const newData = { ...date, date: format(currentDate, "MM/dd") };

      setCurrentDate(newData);
    }

    setShowDailyData(shouldShowDailyData);
  };

  return (
    <Card
      {...{
        days: data.days,
        startDate: data.startDate,
        endDate: data.endDate,
        reward: data.reward,
        currency: data.currency,
        estimate_reward: data.estimate_reward,
        estimate_currency: data.estimate_currency,
        ave_apy: data.ave_apy,
        currentData: currentDate,
        showDailyData,
      }}
    >
      <ChartView
        chartData={data.reward_records}
        onDateChange={onDateChange}
      ></ChartView>
    </Card>
  );
}

export default Chart;
