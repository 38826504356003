import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChartIframeParams } from "./interfaces/chart";
import Navigation from "./navigation";
import ChartContext from "./providers/chartContext";
import { WebviewUtil } from "./utils/webview-utils";

const queryClient = new QueryClient();

function App() {
  const [currentWindow, setCurrentWindow] = useState<
    (Window & typeof globalThis) | null
  >(null);
  useEffect(() => {
    setCurrentWindow(window);
  }, []);

  const [hostWindow, setHostWindow] = useState<any>(null);
  useEffect(() => {
    if (currentWindow) {
      setHostWindow(currentWindow.parent);
      return;
    }
  }, [currentWindow]);

  const [chartConfig, setChartConfig] = useState<ChartIframeParams>({
    token: "",
    productId: "",
    days: 7,
    type: "",
    basePrecision: 2,
    preferredPrecision: 2,
  });
  useEffect(() => {
    function getParentMessage(e: any) {
      let data = e.data;
      if (WebviewUtil.isWebview()) {
        data = JSON.parse(data);
      }
      if (data.type === "chart-data") {
        setChartConfig(data);
      }
      try {
        const event = new CustomEvent('get_parent_message', {
          detail: typeof data === 'object' ? data : JSON.parse(data)
        });
        document.dispatchEvent(event);
      } catch(e) { console.log( e);}
    }

    if (!currentWindow) {
      return;
    }
    // If webview, 
    // currentWindow.addEventListener("message") triggered on ios
    // document.addEventListener("message") triggered on android
    currentWindow.addEventListener("message", getParentMessage, true);
    document.addEventListener("message", getParentMessage, true);
    const postData = (data: any) => {
      const currentWindow: any = window;
      const hostWindow: any = currentWindow.parent;
      if (WebviewUtil.isWebview()) {
        WebviewUtil.emit(data);
        return;
      }

      if (hostWindow) {
        hostWindow.postMessage(data, "*");
      }
    };
    postData("webViewInit");

    return () => {
      document.removeEventListener("message", getParentMessage, true);
      if (!currentWindow) {
        return;
      }
      currentWindow.removeEventListener("message", getParentMessage, true);
    };
  }, [currentWindow, hostWindow]);

  return (
    <QueryClientProvider client={queryClient}>
      <ChartContext.Provider value={chartConfig}>
        <Navigation queryClient={queryClient}></Navigation>
      </ChartContext.Provider>
    </QueryClientProvider>
  );
}

export default App;