import React from "react";
import about from "./about-white.svg";
import { WebviewUtil } from "../../utils/webview-utils";
import "./tooltip.scss";

export const Tooltip: React.FC<{day:number}> = ({day}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <div
      className="tooltipDialog"
      onMouseEnter={() => {
        if (!WebviewUtil.isWebview()) {
          setShowTooltip(true)
        }
      }}
      onMouseLeave={() => {
        if (!WebviewUtil.isWebview()) {
          setShowTooltip(false)
        }
      }}
    >
      <img 
        onClick={() => {
          if (WebviewUtil.isWebview()) {
            WebviewUtil.emit({ type: 'tap-avg-apy' });
          }
        }}
        alt="about logo" 
        height="16" 
        src={about} 
        width="16" 
      />
      {showTooltip && (
        <div className="tooltipDialog__dialog dialog--left">
          <div className="tooltipDialog__dialog__title">{day}-day avg. APR</div>
          <div className="tooltipDialog__dialog__content">Average Annual Percentage Rate for past {day} days</div>
        </div>
      )}
    </div>
  );
};
