import React from "react";
import { ChartIframeParams } from "../interfaces/chart";

const ChartContext = React.createContext<ChartIframeParams>({
  token: "",
  productId: "",
  days: 7,
  type: "",
  basePrecision: 2,
  preferredPrecision: 2,
});

export default ChartContext;
