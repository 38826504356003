enum NumberFormat {
  USD = "en-US",
  INR = "en-IN",
}

export const currencyFormatter = (
  value: string | number | undefined,
  currency: string | undefined
) => {
  if (value === null || value === "undefined") {
    return "- -";
  }

  if (isNaN(Number(value))) {
    return "- -";
  }

  const splitNum = value?.toString().split(".");

  if (!splitNum) {
    return "- -";
  }

  let currencyFormat = NumberFormat.USD;

  switch (currency) {
    case NumberFormat.USD:
      currencyFormat = NumberFormat.USD;
      break;
    case NumberFormat.INR:
      currencyFormat = NumberFormat.INR;
      break;
  }

  if (splitNum.length === 1) {
    return Intl.NumberFormat(currencyFormat).format(Number(value));
  } else {
    return `${Intl.NumberFormat(currencyFormat).format(Number(splitNum[0]))}.${
      splitNum[1]
    }`;
  }
};
