import { Chart } from "@antv/g2";
import { useEffect, useRef, useState } from "react";

import "./aum-reward-estimated-chart.scss";

type EventData = ChangeAmountEvent | InitChartEvent;
type ChangeAmountEvent = {
  type: 'change-amount',
  payload: string;
}
type InitChartEvent = {
  type: 'init-chart',
  payload: InitChartPayload
}
type InitChartPayload = {
  xrexApy: number
  bankApy: number;
}
const CONFIG  = {
  bankColor: '#B7C1DA',
  xrexColor: '#0184FF',
};
function AumRewardEstimatedChart() {
  const chartElement = useRef(null);
  const chartRef: React.MutableRefObject<Chart | null> = useRef(null);
  const [amount, setAmount] = useState('');
  const [initPayload, setInitPayload] = useState<InitChartPayload>();

  const getCurrentChartLazy = (chartElement: HTMLElement): Chart => {
    const chart = chartRef.current;
    if (!chart) {
      return new Chart({
        autoFit: true,
        container: chartElement,
      });
    }

    return chart;
  };
  const onParentMessage = (event: any) => {
    const data = event.detail as EventData; 
    switch(data.type) {
      case 'change-amount':
        setAmount(data.payload);
        break;
      case 'init-chart':
        setInitPayload(data.payload)
        break;
    }
  };
  useEffect(
    () => {
      if (!chartElement.current) {
        return;
      }
      
      const bankApy = (initPayload?.bankApy || 0)
      const xrexApy = (initPayload?.xrexApy || 0)
      const chart = getCurrentChartLazy(chartElement.current);
      chartRef.current = chart;
      
      const amountNumber = amount ? Number(amount) : 0;
      const bankRewardPerYear = amount ? amountNumber + amountNumber * bankApy : 200;
      const xrexRewardPerYear = amount ? amountNumber + amountNumber * xrexApy : 200;
      const chartMax = xrexRewardPerYear * 1.02;
      const data = Array(7).fill(true).map((itr, i) => {
        const month = i * 2;
        let output: any = {
          month
        }
        if (amountNumber) {
          output = {
            ...output,
            rewardBank: amountNumber + amountNumber * bankApy / 12 * month,
            rewardXrex: amountNumber + amountNumber * xrexApy / 12 * month,
          }
        }
        return output;
      }).reverse();
      chart.data(data);
      chart.scale({
        rewardXrex: {
          max: chartMax,
          min: amountNumber,
          
        },
        rewardBank: {
          max: chartMax,
          min: amountNumber,
        },
        month: {
          range: [0.05, 0.95],
          tickCount: 7,
        },
      });
      chart.axis('month', {
        label: {
          formatter: (val) => {
            if (val === '0') return 'Now';
            if (val === '12') return '1Y';
            return `${val}M`;
          },
        },
      });
      chart.axis('rewardXrex', false);
      chart.axis('rewardBank', {
        label: {
          formatter: (val) => {
            return Number(val).toFixed(2);
          },
        },
        grid: {
          line: {
            style: {
              stroke: '#3C4248',
              lineWidth: 1,
              lineDash: [4, 4]
            }  
          }
        },
      })
      chart.line().position('month*rewardBank').color(CONFIG.bankColor).shape('smooth');
      chart.line().position('month*rewardXrex').color(CONFIG.xrexColor).shape('smooth');
      if (amountNumber) {
        chart.annotation().dataMarker({
          top: true,
          position: ['12', xrexRewardPerYear],
          point: null,
          line: null,
          text: {
            content: xrexRewardPerYear.toFixed(6),
            style: {
              fill: 'white',
              opacity: 1,
              textAlign: 'right',
            }
          },
        });
        chart.annotation().dataMarker({
          top: true,
          position: ['12', bankRewardPerYear],
          point: null,
          line: null,
          text: {
            content: bankRewardPerYear.toFixed(6),
            style: {
              fill: 'white',
              opacity: 1,
              textAlign: 'right',
            }
          },
        });
      }
      chart.tooltip(false);
      chart.render();
      return () => {
        chart.clear();
        chart.destroy();
        chartRef.current = null;
      };
    },
    [chartElement.current, amount, initPayload]
  );
  useEffect(() => {
    document.addEventListener('get_parent_message', onParentMessage)
    return () => {
      document.removeEventListener('get_parent_message', onParentMessage)
    }
  }, []);
  return (
    <div className="aum-reward-estimated-card">
      <div 
        id="aum-reward-estimated-chart" 
        ref={chartElement}
      />
      <div className="legend-wrapper">
        <div style={{ flex: 1 }} />
        <div 
          className="dot" 
          style={{ backgroundColor: CONFIG.xrexColor }}
        />
        <div>XREX Rewards</div>
        <div style={{ width: 16 }} />
        <div 
          className="dot" 
          style={{ backgroundColor: CONFIG.bankColor }}
        />
        <div>Avg. USD Bank Deposit</div>
      </div>
    </div>
  );
}

export default AumRewardEstimatedChart;
