import { useEffect, useRef } from "react";
import { Chart } from "@antv/g2";
import { ChartRewardData } from "../../interfaces/userChartData.interface";
import { ChartViewProps } from "../../interfaces/chartView.interface";
import "./chartView.scss";
import {
  chartConfig,
  dateAxisConfig,
  dateAxisConfigWithoutLabel,
  dateScaleConfig,
  hideGridAndLabel,
  tooltipConfig,
} from "../../models/chart.model";
import { WebviewUtil } from "../../utils/webview-utils";
import { fixDecimal } from "../../utils/fixDecimal";

export const ChartView: React.FC<ChartViewProps> = ({
  chartData,
  onDateChange,
}) => {
  const chartElement = useRef(null);
  const chartRef: React.MutableRefObject<Chart | null> = useRef(null);

  useEffect(
    () => {
      if (!chartElement.current) {
        return;
      }

      const chart = getCurrentChartLazy(chartElement.current);
      chartRef.current = chart;

      if (!chartData) {
        return;
      }

      if (chartRef.current) {
        chart.tooltip(tooltipConfig);
        const [maxApy, minApy] = getMaxMinApy([...chartData]);
        createRewardView(chart, [...chartData]);
        createApyView(chart, [...chartData], maxApy, minApy);
        createAvgApyView(chart, [...chartData], maxApy, minApy);

        setEventListener(chart);

        chart.render();
      }

      return () => {
        chart.clear();
        chart.destroy();
        chartRef.current = null;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData]
  );

  const getMaxMinApy = (chartData: ChartRewardData[]) => {
    let maxApy = Number.NEGATIVE_INFINITY;
    let minApy = Number.POSITIVE_INFINITY;
    chartData.forEach((data) => {
      maxApy = Math.max(maxApy, data.apy);
    });

    chartData.forEach((data) => {
      minApy = Math.min(minApy, data.apy);
    });

    return [maxApy, minApy];
  };

  const getCurrentChartLazy = (chartElement: HTMLElement): Chart => {
    const chart = chartRef.current;
    if (!chart) {
      return new Chart(chartConfig(chartElement));
    }

    return chart;
  };

  const setEventListener = (chart: Chart) => {
    chart.on("tooltip:change", onElementMove);
    chart.on("mouseleave", onLeave);
    chart.on("touchend", onLeave);
  };

  const onElementMove = (e: any) => {
    if (!e || !e.data) {
      return;
    }

    onDateChange(true, e.data.items[0].data.date);

    WebviewUtil.emit({
      type: "chartView:onMove",
    });
  };

  const onLeave = () => {
    onDateChange(false);
  };

  const createRewardView = (chart: Chart, data: ChartRewardData[]) => {
    const rewardView = chart.createView({
      region: {
        start: { x: 0, y: 0.7 },
        end: { x: 1, y: 1 },
      },
    });
    rewardView
      ?.data(data)
      .scale({
        reward: {
          type: "linear",
          nice: true,
        },
        date: dateScaleConfig,
      })
      .axis("date", dateAxisConfig)
      .axis("reward", hideGridAndLabel)
      .interval()
      .position("date*reward")
      .color("#01D183")
      .size(16)
      .tooltip(false);
  };

  const createApyView = (
    chart: Chart,
    data: ChartRewardData[],
    maxApy: number,
    minApy: number
  ) => {
    const apyView = chart.createView({
      region: {
        start: { x: 0, y: 0.1 },
        end: { x: 1, y: 0.6 },
      },
    });

    //quick fix for apy to apr
    const transformedData = data.map((item) => {
      return {
        ...item,
        APR: fixDecimal(item.apy, 2),
        date: item.date,
      };
    });

    apyView
      ?.data(transformedData)
      .axis("date", dateAxisConfigWithoutLabel)
      .axis("APR", hideGridAndLabel)
      .scale({
        APR: {
          type: "linear",
          min: minApy,
          max: maxApy,
          tickCount: 10,
          formatter: (value) => `${value}%`,
        },
        date: dateScaleConfig,
      })
      .line()
      .color("#01D183")
      .position("date*APR")
      .tooltip({ fields: ["APR", "reward"] });
  };

  const createAvgApyView = (
    chart: Chart,
    data: ChartRewardData[],
    maxApy: number,
    minApy: number
  ) => {
    const avgApyView = chart?.createView({
      region: {
        start: { x: 0, y: 0.1 },
        end: { x: 1, y: 0.6 },
      },
    });
    avgApyView
      .data(data)
      .axis("avgApy", hideGridAndLabel)
      .axis("date", dateAxisConfigWithoutLabel)
      .scale({
        avgApy: {
          type: "linear",
          min: minApy,
          max: maxApy,
          tickCount: 10,
          formatter: (value) => `${value}%`,
        },
        date: dateScaleConfig,
      })
      .line()
      .position("date*avgApy")
      .style({
        stroke: "#969696",
        lineDash: [3, 3],
      })
      .tooltip(false);
  };

  return <div id="chart" ref={chartElement}></div>;
};
