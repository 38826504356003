import { Widget } from "@typeform/embed-react";
import { useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useMatch } from "react-location";
import { WebviewUtil } from "../../utils/webview-utils";
import "./typeform.scss";

function TypeForm() {
  const {
    params: { formId, hiddenField },
  } = useMatch();

  const debounced = useDebouncedCallback(() => {
    setTimeout(() => {
      const currentWindow: any = window;
      const hostWindow: any = currentWindow.parent;

      if (WebviewUtil.isWebview()) {
        WebviewUtil.emit("typeform:onSubmit");
        return;
      }

      if (hostWindow) {
        hostWindow.postMessage("typeform:onSubmit", "*");
      }
    }, 6000);
  }, 500);

  const hiddenFields = useMemo(() => {
    const field = hiddenField.split("u=");
    return field[1];
  }, [hiddenField]);

  const onAssessmentSubmit = () => {
    debounced();
  };

  if (!formId || !hiddenField) {
    return <p>Loading</p>;
  }

  return (
    <Widget
      id={formId}
      className="xrexForm"
      hidden={{ u: hiddenFields }}
      onSubmit={onAssessmentSubmit}
    />
  );
}

export default TypeForm;
