import { Chart } from "@antv/g2";
import { useEffect, useRef, useState } from "react";
import { getAumProformance } from "../../apis/chart.api";
import { EarnRewards, GetAumProformanceRequestBody } from "../../interfaces/userChartData.interface";
import { fixDecimal } from "../../utils/fixDecimal";

import "./aum-performance-chart.scss";

type ChartData = {
  date: string;
  reward?: string | number;
};
type EventData = InitChartEvent | InitChartDataEvent;
/** @deprecated */
type InitChartEvent = {
  type: 'init-chart',
  payload: {
    productId: string,
    token: string;
  }
}
type InitChartDataEvent = {
  type: 'init-chart-data',
  payload: {
    recordData: EarnRewards[],
  }
}

function AumPerformanceChart() {
  const chartElement = useRef(null);
  const chartRef: React.MutableRefObject<Chart | null> = useRef(null);
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const precision = useRef(4);

  const getCurrentChartLazy = (chartElement: HTMLElement): Chart => {
    const chart = chartRef.current;
    if (!chart) {
      return new Chart({
        autoFit: true,
        container: chartElement,
      });
    }

    return chart;
  };
  const onParentMessage = async (event: any) => {
    const data = event.detail as EventData; 
    switch(data.type) {
      /** @deprecated */
      case 'init-chart':
        const res = await getAumProformance(data.payload as GetAumProformanceRequestBody);
        const recordArray: ChartData[] = (res?.data?.reward_records?.map((record) => {
          const date = new Date(record.date.replace('T', ''));
          const reward = Number(record.reward);

          if (reward > 10000 && precision.current > 0) {
            precision.current = 0;
          } else if (reward > 1000 && precision.current > 1) {
            precision.current = 1;
          } else if (reward > 100 && precision.current > 2) {
            precision.current = 2;
          } else if (reward > 10 && precision.current > 3) {
            precision.current = 3;
          }
          
          return {
            date: date.toDateString(),
            reward: fixDecimal(reward, 6),
          } 
        }) || []);
        setChartData(recordArray.reverse());
        break;

      case 'init-chart-data':
        const chartData: ChartData[] = [];
        let reward = 0;
        for (let i = data.payload.recordData.length - 1 ; i >= 0 ; i--) {
          const current = data.payload.recordData[i];
          reward = Number(current.reward) + reward;
          const date = new Date(current.date.replace('T', ''));
          chartData.unshift({
            date: date.toDateString(),
            reward,
          });
        }
        if (reward > 10000 && precision.current > 0) {
          precision.current = 0;
        } else if (reward > 1000 && precision.current > 1) {
          precision.current = 1;
        } else if (reward > 100 && precision.current > 2) {
          precision.current = 2;
        } else if (reward > 10 && precision.current > 3) {
          precision.current = 3;
        }
        setChartData(chartData.reverse());
        break;
    }
  };
  useEffect(
    () => {
      if (!chartElement.current) {
        return;
      }
      const chart = getCurrentChartLazy(chartElement.current);
      chartRef.current = chart;
      
      chart.data(chartData);
      
      chart.scale({
        reward: {
          min: 0.000001,
          nice: true,
        },
        date: {
          tickCount: 7,
          type: 'time',
        },
      });
      chart.tooltip({
        customItems: (originalItems) => {
          return originalItems.map((itr) => {
            return {
              ...itr,
              value: fixDecimal(Number(itr.value), 6)
            };
          });
        }
      });
      chart.axis('reward', {
        label: {
          formatter: (val) => {
            return (+val / 1).toFixed(precision.current);
          },
        },
        grid: {
          line: {
            style: {
              stroke: '#3C4248',
              lineWidth: 1,
              lineDash: [4, 4]
            }  
          }
        },
      });
      chart.axis('date', {
        label: {
          formatter: (val) => {
            const date = new Date(val);
            return `${date.getMonth() + 1}/${date.getDate()}`;
          },
        },
      });
      
      chart.area().position('date*reward').color('l(270) 0:#0184FF00 1:#0184FF').shape('smooth');
      chart.line().position('date*reward').color('#0184FF').shape('smooth');
      chart.render();
      return () => {
        chart.clear();
        chart.destroy();
        chartRef.current = null;
      };
    },
    [chartElement.current, chartData]
  );
  useEffect(() => {
    document.addEventListener('get_parent_message', onParentMessage)
    return () => {
      document.removeEventListener('get_parent_message', onParentMessage)
    }
  }, []);
  return (<div className="aum-performance-chart-card">
    <div 
      id="aum-performance-chart" 
      ref={chartElement}
    />
  </div>
  );
}

export default AumPerformanceChart;
