export const fixDecimal = (num: number, precision: number ) => {
  if (isNaN(num)) {
    return "--";
  }

  if (isNaN(precision)) {
    precision = 0;
  }

  if (precision === 0) {
    return num.toString().split(".")[0];
  }

  const fNum = num.toString().split(".");

  if (fNum[1]) {
    const sliceDecimal = fNum[1].slice(0, precision);
    return `${fNum[0]}.${sliceDecimal.padEnd(precision, "0")}`;
  } else {
    return `${fNum[0]}.${"".padEnd(precision, "0")}`;
  }
};
