import {
  ScaleOption,
  TooltipItem,
  TooltipOption,
} from "@antv/g2/lib/interface";
import { currencyFormatter } from "../utils/currencyFormatter";

export const dataDef = {
  date: {
    type: "time",
    mask: "MM/DD",
    tickCount: 14,
  },
  apy: {
    type: "linear",
    min: 0,
    max: 12,
  },
  avgApy: {
    type: "linear",
    min: 0,
    max: 12,
  },
  reward: {
    type: "linear",
    min: 0,
    max: 260 + 150,
  },
};

export const chartConfig = (chartElement: HTMLElement) => ({
  container: chartElement,
  autoFit: true,
  height: 128,
  syncViewPadding: true,
  padding: [0, 8, 12, 8],
});

export const tooltipConfig: TooltipOption = {
  showCrosshairs: true,
  showTitle: false,
  crosshairs: {
    type: "x",
    line: {
      style: {
        fill: "#fff",
      },
    },
  },
  marker: {
    fill: "#01D183",
    stroke: "#141414",
  },
  customItems: (items: TooltipItem[]): TooltipItem[] => {
    const apy = items.find((item) => item.name === "APR");
    const reward = items.find((item) => item.name === "reward");
    if (!reward || !apy) {
      return items;
    }

    let transformedReward = { ...reward };

    transformedReward.value = `${currencyFormatter(
      reward.value,
      reward.data.currency
    )} ${reward.data.currency}`;

    return [apy, transformedReward];
  },
};

export const dateAxisConfig = {
  tickLine: null,
  line: null,
};

export const dateAxisConfigWithoutLabel = {
  tickLine: null,
  line: null,
  label: null,
};

export const hideGridAndLabel = {
  grid: null,
  label: null,
};

export const dateScaleConfig: ScaleOption = {
  type: "time",
  mask: "MM/DD",
  tickCount: 14,
};
