import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getEarnSummaries } from "../apis/chart.api";
import {
  StakingDataState,
  ApiResponseStatus,
  EarnDetailStatisticsResponse,
  EarnRewards,
  ChartRewardData,
  UseChartDataResult,
} from "../interfaces/userChartData.interface";
import { format } from "date-fns";
import ChartContext from "../providers/chartContext";
import { fixDecimal } from "../utils/fixDecimal";

export const UseChartData = (): UseChartDataResult => {
  const chartConfig = useContext(ChartContext);
  const { isLoading, data } = useQuery(
    ["statistics"],
    () => {
      if (!chartConfig.token) {
        return null;
      }
      return getEarnSummaries(
        chartConfig.token,
        chartConfig.productId,
        chartConfig.days
      );
    },
    {
      enabled:
        !!chartConfig.token && !!chartConfig.productId && !!chartConfig.days,
      retry: 6,
    }
  );

  const [chartData, setChartData] = useState<StakingDataState>();
  useEffect(() => {
    if (isLoading || data?.code !== ApiResponseStatus.Success || !data.data) {
      return;
    }

    const newChartData = transformDataToChartData(data);
    setChartData(newChartData);
  }, [data, isLoading, chartConfig]);

  const transformDataToChartData = (
    responseData: EarnDetailStatisticsResponse
  ) => {
    const { ave_apy, reward_records, days } = responseData.data;

    let dates = fillEmptyDates(reward_records, days, new Date(responseData.timestamp));
    const aveApyInPercentage = Number(fixDecimal(Number(ave_apy) * 100, 2));
    const [startDate, endDate] = getStartAndEndDates(dates);
    const rewardRecords = fillAvgApyIntoRewardRecords(
      dates,
      aveApyInPercentage
    );

    const { reward, estimate_reward } = responseData.data;

    return {
      ...responseData.data,
      reward_records: rewardRecords,
      ave_apy: aveApyInPercentage,
      reward: fixDecimal(Number(reward), 6),
      estimate_reward: fixDecimal(Number(estimate_reward), 6),
      startDate,
      endDate,
    };
  };

  const getStartAndEndDates = (rewardRecords: EarnRewards[]): Date[] => {
    let startDate;
    let endDate;
    const rewardLength = rewardRecords.length;

    if (rewardLength > 0) {
      startDate = formatDate(rewardRecords[0].date);
      endDate = formatDate(rewardRecords[rewardLength - 1].date);

      return [startDate, endDate];
    }

    return [];
  };

  const fillEmptyDates = (
    rewardRecords: EarnRewards[],
    days: number,
    serverDate: Date,
  ): EarnRewards[] => {
    const remainDays = days - rewardRecords.length;

    if (remainDays < 1) {
      return rewardRecords;
    }

    const initialChartData = (() => {
      const output = [];
      for (let i = 1; i <= 7 ; i++) {
        output.unshift({
          apy: 0,
          avgApy: 0,
          currency: '',
          date: format(new Date(serverDate.getTime() - 86400 * 1000 * i), "yyyy-MM-dd"),
          estimate_currency: '',
          estimate_reward: '',
          reward: ''
        });
      }
      return output;
    })();

    const filledDates = initialChartData.map((fixedDateItr) => {
      const found = rewardRecords.find((itr) => {
        return (itr.date === fixedDateItr.date) 
      });
      return found || fixedDateItr;
    });
    return [...filledDates];
  };

  const fillAvgApyIntoRewardRecords = (
    rewardRecords: EarnRewards[],
    avgApy: number
  ): ChartRewardData[] => {
    return rewardRecords.map((rewards: EarnRewards) => {
      return {
        ...rewards,
        reward: fixDecimal(Number(rewards.reward), 6),
        estimate_reward: fixDecimal(Number(rewards.estimate_reward), 6),
        apy: Number(fixDecimal(Number(rewards.apy) * 100, 2)),
        avgApy,
        date: formatDate(rewards.date),
      };
    });
  };

  const formatDate = (date: string) => {
    const year = +date.split("-")[0];
    const month = +date.split("-")[1] - 1;
    const day = +date.split("-")[2];
    const targetDate = new Date(year, month, day);

    return targetDate;
  };

  return { data: chartData, isLoading };
};
