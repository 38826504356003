import axios from "axios";
import { EarnDetailStatisticsResponse, GetAumProformanceRequestBody } from "../interfaces/userChartData.interface";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const getAumProformance = async ({ 
  token,
  productId,
  days = 30
}: GetAumProformanceRequestBody): Promise<EarnDetailStatisticsResponse | null> => {
  const res = await axios.get<EarnDetailStatisticsResponse>(
    `${baseUrl}/user/v1/earn/aum/products/${productId}/statistics?days=30`,
    { headers: { "exchange-token": token } }
  );

  return res.data;
};
export const getEarnSummaries = async (
  token: string,
  productId:string,
  days: number
): Promise<EarnDetailStatisticsResponse | null> => {
  const res = await axios.get<EarnDetailStatisticsResponse>(
    `${baseUrl}/user/v1/earn/defi/products/${productId}/statistics?days=${days}`,
    { headers: { "exchange-token": token } }
  );

  return res.data;
};
