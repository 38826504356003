import { ReactLocation, Router } from "react-location";
import { QueryClient } from "react-query/types/core";
import AumPerformanceChart from "./pages/aum-performance-chart/aum-performance-chart";
import AumRewardEstimatedChart from "./pages/aum-reward-estimated-chart/aum-reward-estimated-chart";
import Chart from "./pages/chart/chart";
import Home from "./pages/home/home";
import TypeForm from "./pages/typeform/typeform";

const location = new ReactLocation();
interface NavigationProps {
  queryClient: QueryClient;
}

function Navigation({ queryClient }: NavigationProps) {
  return (
    <Router
      location={location}
      routes={[
        { path: "/", element: <Home /> },
        {
          path: "typeform/:formId/:hiddenField",
          element: <TypeForm />,
        },
        {
          path: "/chart",
          element: <Chart />,
        },
        {
          path: "/aum-performance-chart",
          element: <AumPerformanceChart />,
        },
        {
          path: "/aum-reward-estimated-chart",
          element: <AumRewardEstimatedChart />,
        },
      ]}
    ></Router>
  );
}

export default Navigation;
